import { isEqual, each, map, mapValues, noop, values } from 'lodash';
import React, { FunctionComponent, useEffect, useLayoutEffect, useState, ComponentProps } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FilterBar from 'common/components/FilterBar';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import type { ClientContextVariable } from 'common/types/clientContextVariable';

import StorytellerUtils from 'lib/StorytellerUtils';
import Actions from 'Actions';
import { dispatcher as fluxDispatcher } from 'Dispatcher';
import { FilterParameterConfiguration } from 'common/types/reportFilters';

import {
  updateFilterParameterConfiguration,
  updateAllFilterParameterConfigurations
} from 'store/TopLevelActions';
import { selectors } from 'store/selectors/DataSourceSelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';

export interface FilterBarContainerProps {
  /**
   * Parameters are passed in rather than pulled from the story state because there
   * may be parameters that the story doesn't know about, so we fetch them from the
   * data source at render time.
   */
  parameters?: ClientContextVariable[];
  datasetUid: string;
  $element: JQuery;
  editMode: boolean;
}

const FilterBarContainer: FunctionComponent<FilterBarContainerProps> = ({
  parameters,
  datasetUid,
  $element,
  editMode = false
}) => {
  // Keep parameters in state so that we can update the UI immediately when the user changes the data source, rather
  // than waiting for componentGlobalFilter to finish fetching the parameters for the new data source before updating.
  const [reportParameters, setParameters] = useState(parameters);
  const metadata = useSelector(selectors.getDataSourceMetadata, isEqual);
  const filterParameterConfigurations = useSelector(selectors.getFilterParameterConfigurations, isEqual);
  const dispatch = useDispatch();

  const onUpdateAllFilterParameters = (updated: FilterParameterConfiguration[]): void => {
    if (updated) {
      if (editMode) {
        // Story saves can only be triggered by Flux right now
        fluxDispatcher.dispatch({
          action: Actions.ALL_FILTER_PARAMETER_CONFIGURATIONS_UPDATED,
          updatedConfigs: updated
        });
      } else {
        dispatch(
          updateAllFilterParameterConfigurations({
            updatedConfigs: updated
          })
        );
      }
    }
  };

  const onUpdateSingleFilterParameter = (updated: FilterParameterConfiguration, index: number): void => {
    if (updated) {
      if (editMode) {
        // Story saves can only be triggered by Flux right now
        fluxDispatcher.dispatch({
          action: Actions.FILTER_PARAMETER_CONFIGURATION_UPDATED,
          index: index,
          updatedConfig: { type: updated.type, config: updated.config }
        });
      } else {
        dispatch(
          updateFilterParameterConfiguration({
            index: index,
            updatedConfig: { type: updated.type, config: updated.config }
          })
        );
      }
    }
  };

  useEffect(() => {
    setParameters(parameters);
  }, [parameters]);

  if (editMode) {
    // In edit mode, story needs to be prompted to re-render
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      const expandControl = $element.find('.btn-expand-control');
      const rerenderStory = (): void => {
        // The setTimeout is required to ensure that the height change event
        // happens _after_ the filter bar is actually expanded
        setTimeout(() => $element.trigger('component::height-change'), 0);
      };

      expandControl && expandControl.on('click', rerenderStory);

      return (): void => {
        $element.off('click', rerenderStory);
      };
    }, [$element]);
  }

  const filterBarProps: ComponentProps<typeof FilterBar> = {
    onUpdate: noop,
    onUpdateAllFilterParameters,
    onUpdateSingleFilterParameter,
    columns: mapValues(metadata, ({ columns }) => values(columns)),
    computedColumns: [],
    onInEditFilterChange: noop,
    isReadOnly: !editMode,
    // If metadata has not loaded for all datasources yet, then the FilterBar will not render anything.
    dataSource: map(metadata, ({ domainCName, id }) => ({ datasetUid: id, domain: domainCName! })),
    filterParameterConfigurations,
    editMode,
    pendoIds: {
      filterParameterControlContainer: 'global-filter-container-dropdown',
      expandButton: 'global-filter-expand-control',
      resetAllFilters: 'reset-filters-gfb'
    }
  };

  return <FilterBar {...filterBarProps} />;
};

export default FilterBarContainer;
