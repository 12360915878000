import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ForgeIcon } from '@tylertech/forge-react';

import { FilterParameterConfiguration } from 'common/types/reportFilters';
import { selectors } from 'store/selectors/DataSourceSelectors';

import FilterBarContainer, { FilterBarContainerProps } from '../componentGlobalFilter/FilterBarContainer';
import SelectDatasetButton from '../componentGlobalFilter/SelectDatasetButton';
import GlobalFilterPrintMode from 'lib/components/block-component-renderers/componentGlobalFilter/GlobalFiltersPrintMode';

export interface GlobalFilterProps {
  blockId: string;
  componentIndex: number;
  $element: JQuery;
  editMode?: boolean;
}

const GlobalFilter: React.FC<GlobalFilterProps> = ({
  blockId,
  componentIndex,
  $element,
  editMode = false
}) => {
  const metadata = useSelector(selectors.getDataSourceMetadata, _.isEqual);
  const hasEmptyDataSource = useSelector(selectors.hasEmptyDataSource, _.isEqual);
  const parameterFilterConfigurations = useSelector(selectors.getFilterParameterConfigurations, _.isEqual);

  const classes =
    'global-filter-actions-container global-filter-actions-container-forge-viz-style full-width-content';

  const datasetUid = Object.keys(metadata)[0] ?? '';

  const noVisibleGlobalConfigurations: boolean =
    _.isEmpty(parameterFilterConfigurations) ||
    _.every(parameterFilterConfigurations, ({ config }: FilterParameterConfiguration) => config.isHidden);

  if (!editMode && (noVisibleGlobalConfigurations || hasEmptyDataSource)) {
    // Global Filter Bar should be hidden in view mode when there are no visible filters or parameters
    return null;
  } else if (!hasEmptyDataSource && _.isEmpty(metadata)) {
    // If this is configured, but metadata hasn't loaded yet, show some loading state
    return (
      <div className="global-filter background-filter-bar">
        <div className={classes}></div>
      </div>
    );
  } else if (hasEmptyDataSource) {
    return (
      <div className="global-filter background-filter-bar">
        <div className={`${classes} global-filter-unconfigured`}>
          <div className="select-dataset-container">
            <SelectDatasetButton blockId={blockId} componentIndex={componentIndex} />
          </div>
        </div>
      </div>
    );
  }

  const filterBarContainerProps: FilterBarContainerProps = {
    parameters: [],
    datasetUid,
    $element,
    editMode
  };

  return (
    <div className="global-filter background-filter-bar">
      <div className={classes}>
        <ForgeIcon name="filter_list" className="icon-1x global-filter-icon" />
        <div className="horizontal-filter-bar">
          <FilterBarContainer {...filterBarContainerProps} />
        </div>
        <GlobalFilterPrintMode globalFilters={parameterFilterConfigurations} />
      </div>
    </div>
  );
};

export default GlobalFilter;
